import { FC, ReactElement } from 'react';
import { isNumber } from 'lodash';

import { Link } from '@common/atoms/Link';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { Counter as NewsBlockItemCounter, Heading as NewsBlockItemHeading } from '@web/atoms';
import { Flexbox } from '@web/atoms/Flexbox';
import { WebpImage } from '@web/atoms/WebpImage';

import { DefaultProps } from '../NewsBlockItem';

import styles from './NewsBlockListItem.module.scss';

export interface Props extends DefaultProps {
    count?: string | number;
    metaSuffix?: string;
    metaIcon?: ReactElement;
}

export const NewsBlockListItem: FC<Props> = ({
    index,
    src = '',
    title = '',
    metaIcon,
    count,
    date = '',
    metaSuffix,
    url,
}) => {
    const metaString = date ? date : `${count} ${metaSuffix}`;
    return (
        <Flexbox row as={'li'} position={'relative'} classes={styles.NewsBlockListItem}>
            <Link href={url}>
                {isNumber(index) ? <NewsBlockItemCounter classes={styles.counter} tag={index} /> : null}
                <WebpImage
                    defaultSize={WebpWidthEnum.WIDTH_110}
                    className={styles.image}
                    src={src}
                    alt={title}
                    ratio={110 / 60}
                />
                <div className={styles.content}>
                    <NewsBlockItemHeading as={'h4'} className={styles.heading} heading={title} />
                    <div className={styles.meta}>
                        {metaIcon ? metaIcon : null}
                        {metaString}
                    </div>
                </div>
            </Link>
        </Flexbox>
    );
};
