import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FetcherProps, SimpleWidget } from 'web/organisms/SimpleWidget';

import { News } from '@common/clients/api';
import { fetchMostReadNews } from '@web/handlers/fetchMostReadNews';
import { NewsBlockItem, NewsBlockItemProps } from '@web/molecules/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';

import { mapFetchedData, switchNewsBlockTitle } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
}

export const Widget: FC<Props> = ({ dataList }) => {
    const __sidebar = useTranslation('sidebar').t;

    return (
        <SimpleWidget
            widgetHeading={switchNewsBlockTitle('watched', __sidebar)}
            metaKey={'watched'}
            ComponentItem={NewsBlockItem as FC<NewsBlockItemProps>}
            dataList={dataList}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchMostReadNews({ isMedia: true }),
    mapper: mapFetchedData('visitCount', true),
    storageKey: WidgetType.MOST_VIEWED,
};
