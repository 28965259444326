import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { News } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { DateTimeUtil, Format, formatNumericDuration } from '@common/utils/DateTimeUtil';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { NewsBlockItem, NewsBlockItemProps, Variant } from '@web/molecules/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { switchNewsBlockTitle } from '@web/organisms/SideWidgets/utils';
import { FetcherProps, MapperType, SimpleWidget } from '@web/organisms/SimpleWidget';

interface Props {
    dataList?: NewsBlockItemProps<Variant.TIMELINE>[];
}

export const mapFetchedNews: MapperType<News[], NewsBlockItemProps<Variant.TIMELINE>[]> = (data: News[]) =>
    data?.map(
        ({ category = {}, image = '', newsDate, newsTitle: title = '', host, path, ...newsItem }, index) => {
            // FIXME: PB-6441 - implement pro article logic and remove these temporary checks
            const functionality = index % 2 === 0 ? 'pro' : undefined;
            const url = host && newsItem ? `${host}${path}` : '';
            const date = DateTimeUtil.format(new Date(newsDate), Format.TIME_NUMERIC);
            const videoDuration =
                newsItem?.videofeedItem && newsItem.videofeedItem.duration
                    ? formatNumericDuration(newsItem.videofeedItem.duration)
                    : undefined;

            return {
                index,
                styleVariant: Variant.TIMELINE,
                category: {
                    id: index,
                    functionality,
                    ...category,
                },
                url,
                image,
                date,
                title,
                videoDuration,
            };
        },
    );

export const Widget: FC<Props> = ({ dataList = [] }) => {
    const __sidebar = useTranslation('sidebar').t;
    return (
        <SimpleWidget
            metaKey={'recent-news'}
            ComponentItem={NewsBlockItem<Variant.TIMELINE>}
            dataList={dataList}
            titleClasses={'border-second'}
            widgetHeading={switchNewsBlockTitle('recent-news', __sidebar)}
        />
    );
};

export const fetcherProps: FetcherProps<News[] | [], NewsBlockItemProps<Variant.TIMELINE>[] | []> = {
    fetcher: async (contextData: ContextData, isClientSide = false) => {
        const news = (await fetchHomepageNews(contextData, 1, isClientSide)) || [];
        const data = 'data' in news ? news.data : [];
        return data || [];
    },
    mapper: mapFetchedNews,
    storageKey: WidgetType.RECENT_NEWS,
};
