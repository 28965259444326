import { FC, memo } from 'react';

import { SidebarData, WidgetType } from '../Sidebar/types';
import { Widget as LatestPodcasts } from './LatestPodcasts';
import { Widget as MostLikedComments } from './MostLikedComments';
import { Widget as MostViewed } from './MostViewed';
import { NewsPopular } from './NewsPopular';
import { Widget as RecentNews } from './RecentNews';
import { Widget as TransferRumour } from './TransferRumour';
import { Widget as UpcomingGP } from './UpcomingGrandPrix';

export interface Props {
    widgetType: WidgetType;
    sidebarProps: SidebarData;
}

const Widget: FC<Props> = ({ widgetType, sidebarProps }) => {
    switch (widgetType) {
        case WidgetType.MOST_READ:
        case WidgetType.MOST_COMMENTED:
            return (
                <NewsPopular
                    isServerSideRendered
                    dataList={{
                        read: sidebarProps['most-read'] || [],
                        commented: sidebarProps['most-commented'] || [],
                    }}
                />
            );
        case WidgetType.UPCOMING_GP:
            // If this returns null the layout will be abnormal, but it shouldn't happen unless something is wrong.
            return sidebarProps['upcoming-gp'] ? (
                <UpcomingGP {...sidebarProps['upcoming-gp']} hasVerticalSchedule />
            ) : null;
        case WidgetType.LATEST_PODCASTS:
            return <LatestPodcasts dataList={sidebarProps['latest-podcasts']} />;
        case WidgetType.RECENT_NEWS:
            return <RecentNews dataList={sidebarProps['recent-news']} />;
        case WidgetType.TRANSFER_RUMOUR:
            return <TransferRumour dataList={sidebarProps['transfer-rumour']} />;
        case WidgetType.MOST_VIEWED:
            return <MostViewed dataList={sidebarProps['most-viewed']} />;
        case WidgetType.MOST_LIKED_COMMENTS:
            return <MostLikedComments dataList={sidebarProps['most-liked-comments']} />;
        default:
            return null;
    }
};

export const SideWidget = memo(Widget);
