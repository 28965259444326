import { FC } from 'react';

import { Link } from '@common/atoms/Link';
import { News } from '@common/clients/api';
import { Flexbox } from '@web/atoms/Flexbox';
import { Time, TimeStyle } from '@web/atoms/Time';
import { ProTag } from '@web/molecules/ProTag';

import { DefaultProps } from '../NewsBlockItem';

import styles from './TimelineItem.module.scss';

export interface Props extends DefaultProps {
    category?: Partial<News['category']>;
    image?: string;
    videoDuration?: string;
}

export const TimelineItem: FC<Props> = ({
    title = '',
    date: newsTimestamp,
    category,
    videoDuration,
    url,
    ...props
}) => {
    const isProArticle = category?.functionality === 'pro';
    const isHighlighted = props?.index === 0;

    return (
        <Flexbox
            row
            as={'li'}
            position="relative"
            classes={`${styles.TimelineItem} ${isHighlighted ? styles.highlighted : ''}`}
        >
            <Link href={url}>
                {/* News date */}
                <div className={styles['timeline-gutter']}></div>
                <Flexbox column as={'div'} position="relative" classes={styles.content}>
                    {newsTimestamp ? (
                        <Time
                            renderIcon={<span className={styles['timeline-point']}></span>}
                            styleVariant={TimeStyle.TIMESTAMP}
                            time={newsTimestamp}
                        />
                    ) : null}

                    <h4 className={styles.heading}>
                        {isProArticle ? <ProTag /> : null}
                        {/* Video duration */}
                        {videoDuration ? (
                            <Time styleVariant={TimeStyle.DURATION} time={videoDuration || '00:00'} />
                        ) : null}
                        {title}
                    </h4>
                </Flexbox>
            </Link>
        </Flexbox>
    );
};
