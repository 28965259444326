import { FC, Fragment } from 'react';

import { AdPlacement, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Ad } from '@web/molecules/Ad';
import { SidebarSequenceWidgets, WidgetType } from '@web/organisms/Sidebar/types';
import { SidebarSequence } from '@web/routing/types/SidebarSequenceByRoute';

import { SideWidget } from '../SideWidgets/SideWidget';

import styles from './Sidebar.module.scss';

/**
 * Configure which widgets are included for each platform and sequence.
 */
// prettier-ignore
export const PLATFORM_SIDEBAR_WIDGETS: Record<PlatformID, SidebarSequenceWidgets> = {
    [PlatformID.BR] : {
        [SidebarSequence.DEFAULT] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_PODCAST] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA] : [
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC] : [
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
    },
    [PlatformID.GP] : {
        [SidebarSequence.DEFAULT] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_PODCAST] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE] : [
            // WidgetType.UPCOMING_GP,
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA] : [
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX] : [
            WidgetType.MOST_VIEWED,
            WidgetType.MOST_READ,
            WidgetType.MOST_COMMENTED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
    },
    [PlatformID.VI]: {
        [SidebarSequence.DEFAULT]: [WidgetType.RECENT_NEWS],
        [SidebarSequence.RIGHT_COL_PODCAST]: [],
        [SidebarSequence.RIGHT_COL_HOMEPAGE]: [WidgetType.RECENT_NEWS],
        [SidebarSequence.RIGHT_COL_MEDIA]: [],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [],
        [SidebarSequence.RIGHT_COL_MISC]: [],
    },
    [PlatformID.VN] : {
        [SidebarSequence.DEFAULT] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_PODCAST] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA] : [
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
    },
    [PlatformID.VP] : {
        [SidebarSequence.DEFAULT] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_PODCAST] : [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA] : [
            WidgetType.MOST_VIEWED,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC] : [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
    },
};

// Some types are grouped together because they render the same widget and we only want to render one of them
// prettier-ignore
const LAYOUT: WidgetType[][] = [
    [WidgetType.MOST_VIEWED],
    [
        WidgetType.MOST_READ,
        WidgetType.MOST_COMMENTED,
    ],
    [WidgetType.TRANSFER_RUMOUR],
    [WidgetType.MOST_LIKED_COMMENTS],
];

const LAYOUT_PER_PLATFORM: Record<PlatformID, WidgetType[][]> = {
    [PlatformID.BR]: [[WidgetType.LATEST_PODCASTS], ...LAYOUT],
    [PlatformID.GP]: [[WidgetType.UPCOMING_GP], ...LAYOUT, [WidgetType.LATEST_PODCASTS]],
    [PlatformID.VI]: [[WidgetType.RECENT_NEWS], ...LAYOUT, [WidgetType.LATEST_PODCASTS]],
    [PlatformID.VN]: [...LAYOUT, [WidgetType.LATEST_PODCASTS]],
    [PlatformID.VP]: [...LAYOUT, [WidgetType.LATEST_PODCASTS]],
};

interface SidebarProps {
    Injections?: React.ReactNode[];
}

type LeftProps = SidebarProps & { left: true; right?: never };
type RightProps = SidebarProps & { right?: true; left?: never };

export type Props = LeftProps | RightProps;

export const Sidebar: FC<Props> = ({ Injections, ...props }) => {
    const alignmentClass = 'left' in props ? styles['left-side'] : styles['right-side'];

    const { platform, sidebarProps } = useContextData();
    if (!sidebarProps) {
        return null;
    }
    const allowedWidgets = LAYOUT_PER_PLATFORM[platform?.id].filter((widgetTypes) => {
        let foundMatch = false;
        for (const widgetType of widgetTypes) {
            // I'm not happy to cast as any but the data is too broad in the first place.
            const isWidgetWithData =
                sidebarProps[widgetType] && (sidebarProps[widgetType] as any[]).length > 0 ? true : false;
            if (
                PLATFORM_SIDEBAR_WIDGETS[platform.id][sidebarProps.sequence].includes(widgetType) &&
                isWidgetWithData
            ) {
                foundMatch = true;
            }
        }
        return foundMatch;
    });

    const Ads =
        sidebarProps.sequence === SidebarSequence.RIGHT_COL_HOMEPAGE
            ? [
                  <Ad
                      placement={AdPlacement.HOME_SIDEBAR_TOP}
                      key={AdPlacement.HOME_SIDEBAR_TOP}
                      isOptional
                  />,
                  <Ad
                      placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1}
                      key={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1}
                      isOptional
                  />,
                  <Ad
                      placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2}
                      key={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2}
                      isOptional
                  />,
              ]
            : [
                  <Ad
                      placement={AdPlacement.NON_HOME_SIDEBAR_TOP}
                      key={AdPlacement.NON_HOME_SIDEBAR_TOP}
                      isOptional
                  />,
                  <Ad
                      placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1}
                      key={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1}
                      isOptional
                  />,
                  <Ad
                      placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_2}
                      key={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_2}
                      isOptional
                  />,
              ];
    const totalWidgets = allowedWidgets.length;
    const InjectionsOverflow = () => {
        return (
            <>
                {Ads.shift()}
                {Injections?.map((Injection, index) => (
                    <Fragment key={`InjectionsOverflow-${index}`}>
                        {Injection}
                        {Ads.shift()}
                    </Fragment>
                ))}
            </>
        );
    };

    return (
        <aside className={`${styles.Sidebar} ${alignmentClass} sidebar no-match-ticker`}>
            {!allowedWidgets.length ? (
                !Injections?.length ? (
                    <>{Ads.shift()}</>
                ) : (
                    <InjectionsOverflow key={'first'} />
                )
            ) : (
                allowedWidgets.map((widget: WidgetType[], index: number) => {
                    const isLastWidget = index + 1 === totalWidgets;
                    return (
                        <Fragment key={`allowedWidgets-${index}`}>
                            {Injections && Injections.length ? (
                                <>
                                    {Injections.shift()}
                                    {Ads.shift()}
                                </>
                            ) : (
                                Ads.shift()
                            )}
                            <SideWidget key={widget[0]} widgetType={widget[0]} sidebarProps={sidebarProps} />
                            {isLastWidget ? <InjectionsOverflow key={'last'} /> : null}
                        </Fragment>
                    );
                })
            )}
        </aside>
    );
};
