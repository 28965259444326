import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FetcherProps, SimpleWidget } from 'web/organisms/SimpleWidget';

import { Icon } from '@common/atoms/Icon';
import { News } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { fetchCategoryNews, ItemsPerWidget, NewsCatFunctionality } from '@web/handlers/fetchCategoryNews';
import { NewsBlockItem, NewsBlockItemProps } from '@web/molecules/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';

import { mapFetchedData, switchNewsBlockTitle } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
}

export const Widget: FC<Props> = ({ dataList }) => {
    const __sidebar = useTranslation('sidebar').t;

    return (
        <SimpleWidget
            metaKey={'commented'}
            ComponentItem={NewsBlockItem}
            dataList={dataList}
            widgetHeading={switchNewsBlockTitle('rumour', __sidebar)}
            titleClasses={'border-fourth'}
            renderHeader={() => (
                <InternalLink route={Route.TransferRumors}>
                    {__sidebar('rumour.show-more')}
                    <Icon.right />
                </InternalLink>
            )}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchCategoryNews({
        functionality: NewsCatFunctionality.TRANSFER_RUMORS,
        perWidget: ItemsPerWidget.MICRO,
    }),
    mapper: mapFetchedData('commentCount', false),
    storageKey: WidgetType.TRANSFER_RUMOUR,
};
