import { Translate } from 'next-translate';

import { Icon } from '@common/atoms/Icon/Icon';
import { PlatformID } from '@common/clients/api';
import { HighlightedCategory } from '@web/organisms/SideWidgets/types';

export const switchNewsBlockTitle = (category: HighlightedCategory, __translate: Translate) => {
    switch (category) {
        case 'commented':
            return __translate('most-comments.title');
        case 'liked':
            return __translate('popular-comments.title');
        case 'podcast':
            return __translate('podcast.title');
        case 'watched':
            return __translate('most-watched.title');
        case 'read':
            return __translate('most-read.title');
        case 'rumour':
            return __translate('transfers.title');
        case 'recent-news':
            return __translate('recent-news.title');
        default:
            return '';
    }
};

export const switchHighlightedMeta = (category: HighlightedCategory, __translate: Translate) => {
    switch (category) {
        case 'commented':
            return __translate('most-comments.count');
        case 'liked':
            return __translate('popular-comments.count');
        case 'watched':
            return __translate('most-watched.count');
        case 'read':
            return __translate('most-read.count');
        default:
            return '';
    }
};

export const switchMetaIcon = (category: HighlightedCategory) => {
    switch (category) {
        case 'commented':
            return <Icon.comment />;
        case 'liked':
            return <Icon.like />;
        case 'watched':
            return <Icon.show />;
        case 'read':
            return <Icon.views />;
        default:
            return null;
    }
};

export const switchCustomPlatformBranding = (platform: PlatformID) => {
    switch (platform) {
        case PlatformID.GP:
            return 'GPBlog';
        case PlatformID.BR:
            return 'Bright';
        default:
            return platform.toUpperCase();
    }
};

export const switchPopularWidgetPlatformTabs = (platform: PlatformID) => {
    switch (platform) {
        case PlatformID.BR:
            return ['read'];
        default:
            return ['read', 'commented'];
    }
};
